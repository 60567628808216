<template>
    <svg
        id="icon_menu_ships"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
    >
        <g id="Group_58" data-name="Group 58">
            <g
                id="Path_64"
                data-name="Path 64"
                transform="translate(-4.281 -6.019)"
                fill="none"
                fill-rule="evenodd"
            >
                <path
                    d="M16.75,6.019h.062a12.5,12.5,0,0,1,0,25H16.75a12.5,12.5,0,0,1,0-25Z"
                    stroke="none"
                />
                <path
                    d="M 16.75019836425781 7.019001007080078 C 13.68707847595215 7.019001007080078 10.80719757080078 8.214881896972656 8.641067504882812 10.38635063171387 C 6.474298477172852 12.5584716796875 5.280998229980469 15.44665145874023 5.280998229980469 18.51886177062988 C 5.280998229980469 21.59109115600586 6.474298477172852 24.47933197021484 8.641078948974609 26.65151977539062 C 10.80721855163574 28.82307052612305 13.68709754943848 30.01900100708008 16.75019836425781 30.01900100708008 L 16.81207847595215 30.01900100708008 C 19.87505722045898 30.01900100708008 22.75483703613281 28.82307052612305 24.92094802856445 26.65151977539062 C 27.08770751953125 24.47932052612305 28.28099822998047 21.59107971191406 28.28099822998047 18.51886177062988 C 28.28099822998047 15.4466609954834 27.08771896362305 12.55848121643066 24.92095947265625 10.38635063171387 C 22.75486755371094 8.214881896972656 19.87507820129395 7.019001007080078 16.81207847595215 7.019001007080078 L 16.75019836425781 7.019001007080078 M 16.75019836425781 6.019001007080078 L 16.81207847595215 6.019001007080078 C 23.69848823547363 6.019001007080078 29.28099822998047 11.61536026000977 29.28099822998047 18.51886177062988 C 29.28099822998047 25.42236137390137 23.69848823547363 31.01900100708008 16.81207847595215 31.01900100708008 L 16.75019836425781 31.01900100708008 C 9.863508224487305 31.01900100708008 4.280998229980469 25.42236137390137 4.280998229980469 18.51886177062988 C 4.280998229980469 11.61536026000977 9.863508224487305 6.019001007080078 16.75019836425781 6.019001007080078 Z"
                    stroke="none"
                    fill="#ffe0a7"
                />
            </g>
            <path
                id="Path_65"
                data-name="Path 65"
                d="M30.035,15.59,30,15.612a1.373,1.373,0,0,1-1.878-.5l-1.925-3.343a1.38,1.38,0,0,1,.5-1.883l.14-.081A12.483,12.483,0,0,1,30.035,15.59ZM19.882,7.677h-3.85a1.372,1.372,0,0,1-1.361-1.234,12.456,12.456,0,0,1,3.178-.411h.069a12.433,12.433,0,0,1,3.32.452A1.372,1.372,0,0,1,19.882,7.677Zm-3.85,21.658h3.85a1.372,1.372,0,0,1,1.361,1.238,12.434,12.434,0,0,1-3.325.453h-.069a12.454,12.454,0,0,1-3.183-.413A1.372,1.372,0,0,1,16.032,29.334Zm10.161-3.812,1.925-3.343a1.373,1.373,0,0,1,1.869-.509A12.49,12.49,0,0,1,26.694,27.4,1.38,1.38,0,0,1,26.193,25.522ZM5.917,21.675a1.373,1.373,0,0,1,1.878.5l1.925,3.343a1.38,1.38,0,0,1-.5,1.883l-.088.051A12.491,12.491,0,0,1,5.8,21.742Zm3.8-10.186L7.8,14.831a1.373,1.373,0,0,1-1.878.5l-.1-.061a12.491,12.491,0,0,1,3.347-5.7l.058.034A1.38,1.38,0,0,1,9.721,11.489Z"
                transform="translate(-5.383 -6.028)"
                fill="#ffe0a7"
                fill-rule="evenodd"
            />
            <path
                id="Subtraction_43"
                data-name="Subtraction 43"
                d="M-5397.982-313.01h-.047A9.994,9.994,0,0,1-5408-323a9.994,9.994,0,0,1,9.97-10h.047a9.994,9.994,0,0,1,9.97,10A9.994,9.994,0,0,1-5397.982-313.01Zm-.806-15.9-4.183,4.2.84,3.238-2.411,2.416a7.845,7.845,0,0,0,3.365,2.974l2.277-2.283,3.051.8,4.183-4.194-.586-2.268-3.529,3.538-3.392-.885-.882-3.4,3.529-3.537-2.263-.589Z"
                transform="translate(5410.506 335.497)"
                fill="#ffe0a7"
            />
        </g>
    </svg>
</template>
