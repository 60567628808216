
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { iconX } from '@/components/icons'

export default defineComponent({
    components: {
        'icon-x': iconX
    },
    setup() {
        const message = ref<string>('')
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        type CallbackFunction = () => void
        let callback: CallbackFunction

        onBeforeMount(() => {
            emitter.on(EventBuss.MESSAGE, handleHint)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.HINT, handleHint)
        })

        const handleHint = (_message: {
            message: string
            callback: CallbackFunction
        }) => {
            message.value = _message.message
            callback = _message.callback
        }

        const callBackFunc = () => {
            if (callback) {
                callback()
            }

            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'prompt'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        return { callBackFunc, t, message }
    }
})
