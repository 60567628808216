<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="21"
        viewBox="0 0 17 21"
    >
        <path
            id="Polygon_8"
            data-name="Polygon 8"
            d="M9.649,1.377a1,1,0,0,1,1.7,0l8.707,14.1A1,1,0,0,1,19.207,17H1.793a1,1,0,0,1-.851-1.525Z"
            transform="translate(0 21) rotate(-90)"
            fill="#3b241b"
        />
    </svg>
</template>
