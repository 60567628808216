<template>
    <svg
        id="icon_menu_setting"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24.114"
        viewBox="0 0 25 24.114"
    >
        <path
            id="Path_16"
            data-name="Path 16"
            d="M25.051,15.359l-3.1,1.107a9.714,9.714,0,0,1-1.1,2.082l.843,3.171a12.5,12.5,0,0,1-4.835,3.046l-2.514-2.12a9.815,9.815,0,0,1-1.57.126c-.264,0-.527-.014-.786-.033L9.65,25.056A12.479,12.479,0,0,1,4.589,22.4l.586-3.226a9.76,9.76,0,0,1-1.263-1.987L.735,16.329a12.381,12.381,0,0,1-.23-5.693l3.1-1.107a9.735,9.735,0,0,1,1.1-2.08L3.86,4.278A12.5,12.5,0,0,1,8.7,1.232l2.514,2.12a9.952,9.952,0,0,1,1.568-.126c.265,0,.527.014.786.035L15.9.942A12.489,12.489,0,0,1,20.967,3.6L20.38,6.823a9.751,9.751,0,0,1,1.263,1.985l3.178.858A12.416,12.416,0,0,1,25.278,13C25.278,13.806,25.2,13.483,25.051,15.359ZM12.777,6.236A6.763,6.763,0,1,0,19.568,13,6.776,6.776,0,0,0,12.777,6.236Zm0,11.6A4.841,4.841,0,1,1,17.639,13,4.851,4.851,0,0,1,12.777,17.839Z"
            transform="translate(-0.278 -0.942)"
            fill="#ffe0a7"
            fill-rule="evenodd"
        />
    </svg>
</template>
