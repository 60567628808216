
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject, ResponseData } from 'vue'
import { useI18n } from 'vue-i18n'
import {
    IService,
    IRule,
    ISetting,
    IPassword,
    IChip,
    IRecord,
    IExit
} from '@/components/icons'
import { logOut as doLogout } from '@/api'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'

export default defineComponent({
    components: {
        IService,
        IRule,
        ISetting,
        IPassword,
        IChip,
        IRecord,
        IExit
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()
        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'setting'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const showSecurity = () => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'security'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const showChipsOption = () => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'chips'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const showMediaSetting = () => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'media-setting'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const showRule = () => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'rule'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const emitDialogEvent = (name: DialogBoxName) => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const logOut = () => {
            const _params = { memberId: memberId.value, token: token.value }
            store.commit(AppMutation.SHOW_LOADING)

            doLogout(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    const logOutData = response.data as ResponseData<any>
                    if (logOutData.status === 200) {
                        store.commit(AppMutation.CLEAR_ALL)
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            logOutData.description!
                        )
                        store.commit(AppMutation.CLEAR_ALL)
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                })
        }

        const gotoCustumerService = () => {
            window.open(process.env.CUSTUMER_SERVICE, '_blank')
        }

        const user = computed(() => store.getters['user'])
        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])

        return {
            showChipsOption,
            showSecurity,
            logOut,
            close,
            showRule,
            gotoCustumerService,
            emitDialogEvent,
            showMediaSetting,
            t
        }
    }
})
