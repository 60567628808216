<template>
    <svg
        id="icon_home"
        xmlns="http://www.w3.org/2000/svg"
        width="31.305"
        height="28.092"
        viewBox="0 0 31.305 28.092"
    >
        <path
            id="Path_460"
            data-name="Path 460"
            d="M26.421,77.1a1.989,1.989,0,0,1,.686,1.372,1.546,1.546,0,0,1-.5,1.247,1.875,1.875,0,0,1-1.372.5,1.963,1.963,0,0,1-1.247-.436q-.156-.125-.951-.873l-1.9-1.793q-1.107-1.044-2.369-2.245t-2.385-2.26q-1.122-1.06-1.933-1.839t-1.029-.966a2.826,2.826,0,0,0-1.964-.873,3,3,0,0,0-1.9.873q-.312.281-1.169,1.076t-1.98,1.855Q5.283,73.8,4.036,74.951T1.729,77.1Q.669,78.1-.1,78.8t-.92.826a2.077,2.077,0,0,1-1.247.405A1.833,1.833,0,0,1-3.6,79.565,1.665,1.665,0,0,1-4.2,78.209a1.747,1.747,0,0,1,.53-1.263q.187-.187,1.154-1.091T-.2,73.7l2.884-2.681q1.543-1.434,2.868-2.681t2.276-2.136q.951-.889,1.107-1.044a3.681,3.681,0,0,1,2.557-1.169,3.184,3.184,0,0,1,2.338.92q.187.156.779.733t1.45,1.4q.857.826,1.917,1.855t2.151,2.073q1.091,1.044,2.136,2.073T24.176,74.9q.873.826,1.465,1.419ZM9.554,72.581a2.857,2.857,0,0,1,1.871-.764,2.637,2.637,0,0,1,1.964.764q.094.094.561.53L15.135,74.2q.686.655,1.543,1.465t1.762,1.621q2.089,1.933,4.677,4.334v8.684a1.7,1.7,0,0,1-.514,1.216,1.947,1.947,0,0,1-1.387.561H15.322V84.491q0-1.029-.966-1.029H8.4a.807.807,0,0,0-.733.312,1.286,1.286,0,0,0-.2.717q0,.187-.016.951t-.016,1.637v5.005H1.729a2.3,2.3,0,0,1-1.434-.405,1.366,1.366,0,0,1-.53-1.154V81.56q2.557-2.338,4.645-4.24.873-.811,1.746-1.606l1.59-1.45q.717-.655,1.2-1.107t.608-.577Z"
            transform="translate(4.197 -63.992)"
            fill="#c5ab7c"
        />
    </svg>
</template>
