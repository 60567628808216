<template>
    <svg
        id="icon_login_verifcation"
        xmlns="http://www.w3.org/2000/svg"
        width="23.799"
        height="30"
        viewBox="0 0 23.799 30"
    >
        <path
            id="Path_548"
            data-name="Path 548"
            d="M155.4,64.987a.542.542,0,0,1,.542-.542,14.737,14.737,0,0,0,8.88-2.648,4.04,4.04,0,0,1,4.952,0,14.737,14.737,0,0,0,8.88,2.648.542.542,0,0,1,.542.542V79.05c0,6.543-11.9,11.9-11.9,11.9s-11.9-5.353-11.9-11.9V64.987Z"
            transform="translate(-155.4 -60.95)"
            fill="#e0a03d"
        />
        <path
            id="Path_549"
            data-name="Path 549"
            d="M519.468,64.651A14.737,14.737,0,0,1,510.588,62,4.046,4.046,0,0,0,508,61.161a38.536,38.536,0,0,1,2.815,14.992,38.573,38.573,0,0,1-2.8,14.956c.06.03.094.043.094.043s11.9-5.353,11.9-11.9V65.19a.539.539,0,0,0-.542-.538Z"
            transform="translate(-496.211 -61.152)"
            fill="#ffc772"
        />
        <path
            id="Path_550"
            data-name="Path 550"
            d="M290.69,338.754a1.984,1.984,0,0,0-2.8,0l-6.082,6.082-2.919-2.992a1.962,1.962,0,0,0-2.815,0,2.035,2.035,0,0,0,0,2.829l4.266,4.373a2.087,2.087,0,0,0,2.892-.023l7.463-7.463a1.986,1.986,0,0,0,0-2.805Z"
            transform="translate(-271.484 -328.906)"
            fill="#eaeaea"
        />
    </svg>
</template>
