
import { PropType, defineComponent } from 'vue'

export default defineComponent({
    props: {
        fillColor: {
            type: String as PropType<string>,
            default: () => '#ffe0a7'
        }
    }
})
