/**
 * Get Value By key
 * @param responseStr
 * @param key
 * @param spliter
 */
export function getValueByKey(
    responseStr: string,
    key: string,
    spliter = '&'
): string | undefined {
    const keyValues = responseStr.split(spliter)
    let value

    for (let i = 0; i < keyValues.length; i++) {
        const item = keyValues[i]
        if (item.startsWith(`${key}=`)) {
            const keyValue = item.split('=')
            if (keyValue.length > 1) {
                value = keyValue[1]
            } else {
                value = undefined
                break
            }
        }
    }
    return value
}
/**
 * Get the Value of KeyValue
 * @param keyValue
 */
export function getValueOfKeyValue(keyValue: string): string | undefined {
    if (keyValue === '') return undefined
    const value = keyValue.split('=')
    if (value.length > 1) {
        return value[1]
    }
    return undefined
}
