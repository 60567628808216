import { ref } from 'vue'

export type Asset = { name: string; src: string }

export function useAssetLoader() {
    const isLoaded = ref(false)
    let loadedCount = 0

    const pcAssets: Asset[] = [
        {
            name: 'd-bg.png',
            src: require('@/assets/imgs/d-bg.png')
        },
        {
            name: 'but_login_test',
            src: require('@/assets/imgs/pc/but_login_test.png')
        },
        {
            name: 'but_login_confirm',
            src: require('@/assets/imgs/pc/but_login_confirm.png')
        },
        {
            name: 'bg_pop_top_small',
            src: require('@/assets/imgs/pc/bg_pop_top_small.png')
        },
        {
            name: 'header_bg',
            src: require('@/assets/imgs/pc/header_bg.png')
        },
        {
            name: 'lb_menu_bg',
            src: require('@/assets/imgs/pc/lb_menu_bg.png')
        },
        {
            name: 'lb_menu_bg_a_1',
            src: require('@/assets/imgs/pc/lb_menu_bg_a_1.png')
        },
        {
            name: 'lb_menu_bg_d_1',
            src: require('@/assets/imgs/pc/lb_menu_bg_d_1.png')
        },
        {
            name: 'lb_menu_bg_d_l',
            src: require('@/assets/imgs/pc/lb_menu_bg_d_l.png')
        },
        {
            name: 'lb_menu_bg_d_r',
            src: require('@/assets/imgs/pc/lb_menu_bg_d_r.png')
        },
        {
            name: 'lb_menu_bg_a_r',
            src: require('@/assets/imgs/pc/lb_menu_bg_a_r.png')
        },
        {
            name: 'bg_bet2',
            src: require('@/assets/imgs/pc/bg_bet2.png')
        },
        {
            name: 'bg_pop_top',
            src: require('@/assets/imgs/pc/bg_pop_top.png')
        },
        {
            name: 'switch_thumb',
            src: require('@/assets/imgs/switch_thumb.png')
        },
        {
            name: 'bg_multitle_down',
            src: require('@/assets/imgs/pc/bg_multitle_down.png')
        },
        {
            name: 'icon_multiple_confirm',
            src: require('@/assets/imgs/pc/icon_multiple_confirm.png')
        },
        {
            name: 'bg_multitle_table',
            src: require('@/assets/imgs/pc/bg_multitle_table.png')
        }
    ]
    const mobileAssets: Asset[] = [
        {
            name: 'but_login_test',
            src: require('@/assets/imgs/m/but_login_test.png')
        },
        {
            name: 'but_login_line_confirm',
            src: require('@/assets/imgs/m/but_login_line_confirm.png')
        },
        {
            name: 'bg_login',
            src: require('@/assets/imgs/m/bg_login.png')
        },
        {
            name: 'bg_pop_top',
            src: require('@/assets/imgs/m/bg_pop_top.png')
        },
        {
            name: 'bg_lobby_top',
            src: require('@/assets/imgs/m/bg_lobby_top.png')
        },
        {
            name: 'bg_table_top',
            src: require('@/assets/imgs/m/bg_table_top.png')
        }
    ]

    const assets: Asset[] = [...pcAssets, ...mobileAssets]
    const loadImageAsset = (res: Asset) => {
        return new Promise((resolve, reject) => {
            const img = new Image()

            img.src = res.src

            img.onload = () => {
                loadedCount++
                if (loadedCount === assets.length) isLoaded.value = true
                resolve(img)
            }

            img.onerror = img.onabort = () => {
                loadedCount++
                reject(res)
            }
        })
    }

    const loadImages = () => {
        assets.map((img) => loadImageAsset(img).catch((err) => err))
    }

    return {
        isLoaded,
        loadImages
    }
}
