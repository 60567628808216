<template>
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_game_cahngeshoes</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_game"
                transform="translate(-1140.000000, -25.000000)"
                fill="#493212"
                fill-rule="nonzero"
            >
                <g id="编组-35" transform="translate(1125.000000, 15.000000)">
                    <path
                        d="M32.627,26.205 C30.731,28.57 27.945,30 24.923,30 C20.146,30 16.043,26.432 15,21.484 L16.985,21 C17.819,24.955 21.102,27.81 24.923,27.81 C27.409,27.81 29.694,26.6 31.213,24.614 L29,22.125 L35,22.125 L35,28.875 L32.627,26.205 Z M17.373,13.795 C19.269,11.43 22.055,10 25.077,10 C29.854,10 33.957,13.568 35,18.516 L33.015,19 C32.181,15.045 28.898,12.19 25.077,12.19 C22.591,12.19 20.306,13.4 18.787,15.386 L21,17.875 L15,17.875 L15,11.125 L17.373,13.795 Z"
                        id="icon_game_cahngeshoes"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

