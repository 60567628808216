
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const { locale, t } = useI18n()

        const langCode = ref<string>('')

        const isMobile = computed((): boolean => store.getters['isMobile'])

        const languages: Array<{ code: string; name: string }> = [
            {
                code: 'zh-cn',
                name: '中文简体'
            },
            {
                code: 'zh-hk',
                name: '中文繁體'
            },
            {
                code: 'en',
                name: 'English'
            },
            {
                code: 'kor',
                name: '한국인'
            }
        ]

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'lang'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const handleSelectLanguage = (_langCode: string) => {
            if (langCode.value !== _langCode) langCode.value = _langCode
        }

        const confirmLanguage = () => {
            if (activeLang.value !== langCode.value) {
                store.commit(AppMutation.SET_LANG, langCode.value)
                locale.value = langCode.value
            }
            close()
        }

        const activeLang = computed((): string => store.getters['lang'])

        onMounted(() => {
            langCode.value = activeLang.value
        })

        return {
            isMobile,
            languages,
            activeLang,
            langCode,
            handleSelectLanguage,
            confirmLanguage,
            close,
            t
        }
    }
})
