import { pingServer } from '@/api'
import { CookiesToken } from '@/types/CookiesToken'
import {
    getBrowserLanguage,
    getCookieValue,
    getDeviceOrientation,
    isMobile,
    setCookieValue
} from '@/utils'
import Cookies from 'js-cookie'
import { AppState, ChipOption, resolveComponent, State, TableData } from 'vue'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AppActions, AppMutation } from '../types'

const state: AppState = {
    device: { display: isMobile(), orientation: getDeviceOrientation() },
    showLoading: false,
    canPlaySound: parseInt(getCookieValue(CookiesToken.SOUND) || '1'),
    isUserInteracted: -1,
    lang: getCookieValue(CookiesToken.LANG) ?? getBrowserLanguage(),
    isfullScreen: false,
    video: parseInt(getCookieValue(CookiesToken.VIDEO) || '1'),
    currentChip: parseInt(getCookieValue(CookiesToken.CURRENT_CHIP) || '0'),
    customchip: parseInt(getCookieValue(CookiesToken.CUSTOM_CHIP) || '0'),
    chips: getCookieValue(CookiesToken.CHIPS)?.toObject<ChipOption>() || {
        id: 1,
        value: [5, 10, 20, 50, 100, 500, 1000, 10000]
    },
    currentDesk: parseInt(getCookieValue(CookiesToken.DESK) || '-1'),
    line: parseInt(getCookieValue(CookiesToken.LINE) ?? '0'),
    lineStr: getCookieValue(CookiesToken.LINE_STR)?.b64decode() ?? ''
}

const mutations: MutationTree<AppState> = {
    [AppMutation.SET_ORIENTATION](state, payload: 'landscape' | 'portrait') {
        state.device.orientation = payload
    },
    [AppMutation.HIDE_LOADING](state) {
        state.showLoading = false
    },
    [AppMutation.SHOW_LOADING](state) {
        state.showLoading = true
    },
    [AppMutation.SET_SOUND](state, payload: boolean) {
        if (payload) state.canPlaySound = 1
        else state.canPlaySound = 0
        setCookieValue(CookiesToken.SOUND, String(payload))
    },
    [AppMutation.SET_USER_INTERACTION](state) {
        state.isUserInteracted = 1
    },
    [AppMutation.SET_LANG](state, payload: string) {
        if (state.lang !== payload) {
            state.lang = payload
            setCookieValue(CookiesToken.LANG, payload)
        }
    },
    [AppMutation.SET_FULL_SCREEN](state, payload: boolean) {
        state.isfullScreen = payload
    },
    [AppMutation.SET_VIDEO](state, payload: boolean) {
        if (payload) state.video = 1
        else state.video = 0

        setCookieValue(CookiesToken.VIDEO, String(state.video))
    },
    [AppMutation.SET_CHIPS](state, payload) {
        state.chips = payload
        setCookieValue(CookiesToken.CHIPS, JSON.stringify(payload))
    },
    [AppMutation.SET_CURRENT_CHIPS](state, payload: number) {
        state.currentChip = payload
        setCookieValue(CookiesToken.CURRENT_CHIP, String(payload))
    },
    [AppMutation.SET_CUSTOM_CHIP](state, payload: number) {
        if (state.customchip !== payload) {
            state.customchip = payload
            setCookieValue(CookiesToken.CUSTOM_CHIP, String(payload))
        }
    },
    [AppMutation.SET_DESK](state, payload: number) {
        state.currentDesk = payload
        setCookieValue(CookiesToken.DESK, String(payload))
    },
    [AppMutation.APP_SET_LINE](state, payload: number) {
        state.line = payload
        setCookieValue(CookiesToken.LINE, String(state.line))
    },
    [AppMutation.APP_SET_LINE_STR](state, payload: string) {
        state.lineStr = payload
        setCookieValue(CookiesToken.LINE_STR, payload.b64encode())
    }
}

const getters: GetterTree<AppState, State> = {
    isMobile(state) {
        return state.device.display
    },
    deviceOrientation(state) {
        return state.device.orientation
    },
    showLoading(state) {
        return state.showLoading
    },
    canPlaySound(state) {
        return state.canPlaySound === 1
    },
    isUserInteracted(state) {
        return state.isUserInteracted !== -1
    },
    lang: (state) => state.lang,
    isfullScreen(state) {
        return state.isfullScreen
    },
    soundEffect(state) {
        return state.canPlaySound
    },
    videoIsOn(state) {
        return state.video === 1
    },
    chips(state) {
        return state.chips
    },
    customChip(state) {
        return state.customchip
    },
    currentChip(state) {
        return state.currentChip
    },
    currentDesk(state) {
        return state.currentDesk
    },
    serverLine(state): number {
        return state.line
    },
    serverLineStr(state): string {
        return state.lineStr
    }
}

const actions: ActionTree<AppState, State> = {
    [AppActions.PING_SERVER](
        { commit },
        params: { line: number; url: string }
    ): Promise<unknown> {
        return new Promise<unknown>((resolve, reject) => {
            const { line, url } = params

            pingServer(line, url)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export const app: Module<AppState, State> = {
    state,
    mutations,
    getters,
    actions
}
