<template>
    <svg
        width="25px"
        height="15px"
        viewBox="0 0 25 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon_lobby_arrow</title>
        <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="DeskTop_lobby"
                transform="translate(-892.000000, -28.000000)"
                fill="#533e0b"
                fill-rule="nonzero"
            >
                <g id="游戏分类" transform="translate(679.000000, 13.000000)">
                    <path
                        d="M222.35718,28.5090517 L213.400143,17.9067525 C212.77158,17.2441088 212.928721,16.2501433 213.557284,15.5874995 C214.185848,14.9248558 215.128694,15.0905168 215.757258,15.7531605 L224.871436,26.3554597 C225.028577,26.5211206 225.342859,26.6867815 225.657141,26.6867815 C225.971423,26.6867815 226.285705,26.5211206 226.599987,26.1897987 L235.242742,15.5874995 C235.871306,14.9248558 236.814152,14.7591949 237.442716,15.4218386 C238.07128,16.0844823 238.22842,17.0784479 237.599856,17.7410916 L228.957102,28.3433908 C228.171397,29.5030172 227.07141,30 225.657141,30 C224.400013,30 223.300026,29.5030172 222.35718,28.5090517 Z"
                        id="icon_lobby_arrow"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>