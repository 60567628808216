export class BeadItems {
    x: number
    y: number
    type: number
    pair = 0

    constructor(x: number, y: number, type: number, pair = 0) {
        this.x = x
        this.y = y
        this.type = type
        this.pair = pair
    }
}
