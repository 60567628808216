
import { useStore } from '@/store'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onMounted,
    ref,
    ResponseData
} from 'vue'
import { IBalance, iconX } from '@/components/icons'
import { AppMutation } from '@/store/types'
import { giveFee } from '@/api'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    components: {
        IBalance,
        'icon-x': iconX
    },
    setup() {
        const store = useStore()

        const emitter = inject('emitter') as Emitter

        const isMobile = computed((): boolean => store.getters['isMobile'])

        const tipAmount = ref<string>('')
        const { t } = useI18n()

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'tipbox'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            let tableId = urlParams.get('desk')

            if (!tableId) {
                // check if multiple desk is available
                let tables: number[] = []

                multitableDesks.value.forEach((_id) => {
                    if (_id !== 0) {
                        tables.push(_id)
                    }
                })

                if (tables.length > 0) {
                    const index = Math.floor(Math.random() * tables.length)
                    tableId = String(tables[index])
                }
            }

            if (tableId) {
                const _params = {
                    memberId: memberid.value,
                    tableId: tableId,
                    feeAmount: tipAmount.value,
                    status: 6,
                    token: token.value
                }

                store.commit(AppMutation.SHOW_LOADING)
                giveFee(_params)
                    .then((response) => {
                        store.commit(AppMutation.HIDE_LOADING)
                        if (response.data.status === 200) {
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                response.data.data.content
                            )

                            store.commit(
                                AppMutation.SET_COIN,
                                response.data.data.amount
                            )

                            close()
                        } else if (response.data.status === 100) {
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                response.data.description
                            )
                        }
                    })
                    .catch(() => {
                        store.commit(AppMutation.HIDE_LOADING)
                        close()
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('prompts.networkerror')
                        )
                    })
            } else {
                close()
            }
        }

        const cancel = () => {
            close()
        }

        const onCapchaKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() !== 'enter') {
                if (event.key.toLowerCase() === 'e') {
                    event.preventDefault()
                    return
                }

                if (
                    document.getSelection()?.toString() ===
                    tipAmount.value.toString()
                ) {
                    return
                }
            }

            if (event.key.toLowerCase() === 'enter') {
                confirm()
            }
        }

        onMounted(() => {
            //
        })

        const memberid = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])
        const multitableDesks = computed(
            (): number[] => store.getters['multitableDesks']
        )

        return {
            isMobile,
            tipAmount,
            confirm,
            close,
            cancel,
            onCapchaKeydown,
            t
        }
    }
})
