<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.829"
        height="14.828"
        viewBox="0 0 26.829 14.828"
    >
        <g id="icon_login_srow" transform="translate(1.414 1.414)">
            <path
                id="Path_546"
                data-name="Path 546"
                d="M1071.343,445.923l12,12,12-12"
                transform="translate(-1071.343 -445.923)"
                fill="none"
                stroke="#654b31"
                stroke-linecap="round"
                stroke-width="2"
            />
        </g>
    </svg>
</template>
