<template>
    <svg
        id="icon_lobby_gold"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <path
            id="Subtraction_44"
            data-name="Subtraction 44"
            d="M-3368-341a10.011,10.011,0,0,1-10-10,10.011,10.011,0,0,1,10-10,10.011,10.011,0,0,1,10,10A10.011,10.011,0,0,1-3368-341Zm-3.575-6.837h0l-.48,1.413a6.1,6.1,0,0,0,3.418.952v1.766h1.3v-1.827c2.34-.285,3.388-1.732,3.388-3.04,0-1.7-.9-2.552-3.433-3.255-1.92-.565-2.668-1.06-2.668-1.766,0-.416.216-1.382,2.218-1.382a5.852,5.852,0,0,1,2.983.753l.479-1.414a5.474,5.474,0,0,0-2.893-.828v-1.828h-1.3v1.858c-2.4.282-3.252,1.716-3.252,2.964,0,1.54,1.123,2.535,3.642,3.225,1.792.462,2.459.949,2.459,1.8,0,.909-.965,1.474-2.519,1.474a6.079,6.079,0,0,1-3.342-.86Z"
            transform="translate(3378 361)"
            fill="#ffdc7b"
        />
    </svg>
</template>
