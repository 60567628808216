enum MessageBoxActionType {
    close = 'close',
    open = 'open'
}

type MessageBoxEvent = {
    type: MessageBoxActionType
    message?: string | unknown
    name: DialogBoxName
}

type DialogBoxName =
    | 'rule'
    | 'setting'
    | 'security'
    | 'chips'
    | 'record'
    | 'exit'
    | 'limit'
    | 'lang'
    | 'media-setting'
    | 'agree'
    | 'demo'
    | 'message'
    | 'tipbox'
    | 'tipbox-concent'
    | 'confirm-booking'
    | 'required-change-password'
    | 'prompt'

export { MessageBoxActionType, MessageBoxEvent, DialogBoxName }
