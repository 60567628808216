<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.127"
        height="13.563"
        viewBox="0 0 24.127 13.563"
        class="svg"
    >
        <g id="icon_pop__arrow" transform="translate(2.121 2.121)">
            <path
                id="Path_513"
                data-name="Path 513"
                d="M243.083,16.357l9.942,9.942,9.942-9.942"
                transform="translate(-243.083 -16.357)"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
            />
        </g>
    </svg>
</template>
<style lang="scss" scoped>
.svg {
    stroke: #5a3e43;
}
</style>
