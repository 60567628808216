<template>
    <svg
        id="icon_login_diantou"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="27.508"
        viewBox="0 0 30 27.508"
    >
        <path
            id="Path_537"
            data-name="Path 537"
            d="M54.7,192.361H27.111a1.2,1.2,0,0,1-1.2-1.2V169.191a1.2,1.2,0,0,1,1.2-1.2H54.7a1.2,1.2,0,0,1,1.2,1.2v21.97a1.2,1.2,0,0,1-1.2,1.2Z"
            transform="translate(-25.907 -164.856)"
            fill="#e0a03d"
        />
        <path
            id="Path_538"
            data-name="Path 538"
            d="M192.614,868.506h1.394v2.824h-1.394Z"
            transform="translate(-187.485 -843.822)"
            fill="#af884c"
        />
        <path
            id="Path_539"
            data-name="Path 539"
            d="M89.1,90.946H87.51a3.237,3.237,0,0,1-3.235-3.237V69.437A3.236,3.236,0,0,1,87.51,66.2H89.1a3.237,3.237,0,0,1,3.237,3.237V87.711A3.237,3.237,0,0,1,89.1,90.946Z"
            transform="translate(-82.479 -66.201)"
            fill="#ffc772"
        />
        <path
            id="Path_540"
            data-name="Path 540"
            d="M458.265,284.673H446.18a.74.74,0,0,1-.74-.74V281.47a.739.739,0,0,1,.74-.74h12.085a.739.739,0,0,1,.74.74v2.462a.739.739,0,0,1-.74.74Z"
            transform="translate(-432.533 -274.13)"
            fill="#d3d3d3"
        />
        <path
            id="Path_541"
            data-name="Path 541"
            d="M445.391,514.56h2.322v2.323h-2.323v-2.322Zm5.624,0h2.322v2.323h-2.322v-2.322Zm5.624,0h2.322v2.323h-2.322v-2.322Zm-11.247,3.6h2.322v2.323h-2.323v-2.323Zm5.624,0h2.322v2.323h-2.322Zm5.624,0h2.322v2.323h-2.322Zm-11.247,3.591h2.322v2.323h-2.323v-2.323Zm5.624,0h2.322v2.323h-2.322Z"
            transform="translate(-432.483 -500.766)"
            fill="#fff"
        />
        <path
            id="Path_542"
            data-name="Path 542"
            d="M811.008,748.288h2.322v2.323h-2.322Z"
            transform="translate(-786.854 -727.303)"
            fill="#ffd858"
        />
        <path
            id="Path_543"
            data-name="Path 543"
            d="M672.17,284.622h-6.263v-3.944h6.263a.52.52,0,0,1,.52.52v2.908a.517.517,0,0,1-.52.517Z"
            transform="translate(-646.217 -274.079)"
            fill="#eaeaea"
        />
        <path
            id="Path_544"
            data-name="Path 544"
            d="M483.123,514.56h1.159v2.323h-1.159v-2.322Zm5.624,0h1.159v2.323h-1.159v-2.322Zm5.624,0h1.159v2.323H494.37v-2.322Zm0,3.6h1.159v2.323H494.37Zm-5.624,0h1.159v2.323h-1.159Zm0,3.593h1.159v2.323h-1.159Zm-5.624-3.593h1.159v2.323h-1.159Zm0,3.593h1.159v2.323h-1.159Z"
            transform="translate(-469.056 -500.766)"
            fill="#d2edf7"
        />
        <path
            id="Path_545"
            data-name="Path 545"
            d="M848.691,748.288h1.159v2.323h-1.159Z"
            transform="translate(-823.377 -727.303)"
            fill="#fdc223"
        />
    </svg>
</template>
