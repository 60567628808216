
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    UserInfo,
    watch
} from 'vue'
import { useViewPort } from './composables/useViewPort'
import { useStore } from './store'
import AppLoader from '@/components/app-loader.vue'
import { EventBuss, SOCKET_MESSAGE } from './types/global'
import { AppMutation } from './store/types'
import screenfull from 'screenfull'
import { isMobile as isAMobile } from './utils'
import {
    MsgBoxLanguage,
    MsgBoxSetting,
    MsgBoxSecurity,
    MsgBoxAgree,
    MsgBoxChip,
    MsgBoxDemo,
    MsgGameRule,
    MsgRecord,
    MsgBoxMessage,
    MsgBoxTip,
    MsgBoxTipConcent,
    MsgBoxConfirmBooking,
    MsgBoxMediaSetting,
    MsgBoxRequiredChangePassword,
    MsgPrompt
} from '@/components/dialog'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from './types/MessageBox'
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from './router'
import { MJWebsocket } from './utils/websocket'
import audioPlayer from './utils/sounds'
import { useI18n } from 'vue-i18n'
import { useAssetLoader } from './composables/useAssetLoader'

export default defineComponent({
    components: {
        'app-loader': AppLoader,
        'msg-box-language': MsgBoxLanguage,
        'msg-box-setting': MsgBoxSetting,
        'msg-box-security': MsgBoxSecurity,
        'msg-box-agree': MsgBoxAgree,
        'msg-box-chips': MsgBoxChip,
        'msg-box-demo': MsgBoxDemo,
        'msg-box-rule': MsgGameRule,
        'msg-box-record': MsgRecord,
        'msg-box-message': MsgBoxMessage,
        'msg-box-tip': MsgBoxTip,
        'msg-box-tip-concent': MsgBoxTipConcent,
        'msg-box-confirm-booking': MsgBoxConfirmBooking,
        'msg-box-media-setting': MsgBoxMediaSetting,
        'msg-required-change-password': MsgBoxRequiredChangePassword,
        'msg-prompt': MsgPrompt
    },
    setup() {
        const appRoot = ref<HTMLElement | undefined>(undefined)
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const route = useRoute()
        const router = useRouter()
        const { t } = useI18n()

        let isPageBlured = false

        const _ws: MJWebsocket = new MJWebsocket(emitter)

        const toastMessage = ref<string>('This is Toast')
        const toastMessageCounter = ref<number>(0)

        const dialogCollection = ref<string[]>([])

        const showLanguageDialogBox = ref<boolean>(false)
        const showSettingDialogBox = ref<boolean>(false)
        const showSecurityDialogBox = ref<boolean>(false)
        const showAgreeDialogBox = ref<boolean>(false)
        const showChipsDialogBox = ref<boolean>(false)
        const showDemoLogin = ref<boolean>(false)
        const showRuleDialogBox = ref<boolean>(false)
        const showRecordDialogBox = ref<boolean>(false)
        const showMessageDialogBox = ref<boolean>(false)
        const showMessageDialogTipBox = ref<boolean>(false)
        const showMessageDialogTipBoxConcent = ref<boolean>(false)
        const showMessageDialogVipBox = ref<boolean>(false)
        const showMessageDialogMediaSettingBox = ref<boolean>(false)
        const showRequiredChangePassword = ref(false)
        const showMessageDialogPrompt = ref(false)
        let clientTimeOutStarted = false
        let clientTimeOutSession: number | boolean = false

        let toastMessageInterval: boolean | number = false

        const showMainView = ref(true)

        const { loadImages } = useAssetLoader()

        //#region Computed Helpers
        const isMobile = computed((): boolean => store.getters['isMobile'])
        const deviceOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )
        //#endregion

        const { windowResize } = useViewPort(appRoot.value)

        //#region Vue Helpers
        onBeforeMount(() => {
            // preload image assets
            loadImages()

            window.addEventListener('resize', handleResizeWindow)

            window.addEventListener(
                'visibilitychange',
                handlePageVisibilityChange
            )

            emitter.on(EventBuss.TOAST_MESSAGE, handleToastMessage)
            emitter.on(EventBuss.DIALOG, handleDialogEvent)
            emitter.on(EventBuss.REQUEST_FULL_SCREEN, requestFullScreen)
            emitter.on(EventBuss.SOCKET_CONNECT, connectToWebsocket)
            emitter.on(EventBuss.SOCKET_CLOSE, handleCloseSocket)
            // emitter.on(EventBuss.START_CLIENT_TIME_OUT, startClientTimeOut)

            if (isMobile.value) {
                window.addEventListener(
                    'orientationchange',
                    handleOrientationChange
                )
            }

            window.addEventListener('blur', handlePageBlur)
            window.addEventListener('focus', handlePageFocus)

            initWebSocket()
        })

        onMounted(() => {
            handleResizeWindow()
            if (!audioPlayer.isLoaded) {
                audioPlayer.isOn = canPlaySound.value ? 1 : 0
                audioPlayer.LoadSounds()
            }

            if (token.value && !_ws.isConnected) connectToWebsocket()
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.TOAST_MESSAGE, handleToastMessage)
            emitter.off(EventBuss.DIALOG, handleDialogEvent)
            emitter.off(EventBuss.REQUEST_FULL_SCREEN, requestFullScreen)
            emitter.off(EventBuss.SOCKET_CONNECT, connectToWebsocket)
            emitter.off(EventBuss.SOCKET_CLOSE, handleCloseSocket)

            window.removeEventListener(
                'visibilitychange',
                handlePageVisibilityChange
            )

            window.removeEventListener('blur', handlePageBlur)
            window.removeEventListener('focus', handlePageFocus)

            if (isMobile.value) {
                window.removeEventListener(
                    'orientationchange',
                    handleOrientationChange
                )
            }
        })
        //#endregion

        //#region Helpers

        const handlePageBlur = () => {
            isPageBlured = true
        }

        const handlePageFocus = () => {
            if (isPageBlured === true) {
                handlePageVisibilityChange()
            }
        }

        const handlePageVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                isPageBlured = false
                emitter.emit(EventBuss.PAGE_ON_VISIBLE)
            }
        }

        const reduceClientTimeOut = () => {
            clientTimeOutSession = setTimeout(() => {
                let _count = playerTimeOut.value
                _count--

                console.log(
                    `timeout remaining before logout IDLE ${_count} mins `
                )

                if (_count <= 0) {
                    stopClientTimeOut()
                    _ws.close()
                    window.sessionStorage.clear()

                    const evt: MessageBoxEvent = {
                        type: MessageBoxActionType.open,
                        name: 'message'
                    }
                    emitter.emit(EventBuss.DIALOG, evt)
                    setTimeout(() => {
                        emitter.emit(
                            EventBuss.HINT,
                            t('prompts.nobettimeoutidle')
                        )
                    }, 250)
                    store.commit(AppMutation.SET_CLIENT_TIME_OUT, 5)
                } else {
                    store.commit(AppMutation.SET_CLIENT_TIME_OUT, _count)
                    reduceClientTimeOut()
                }
            }, 1000 * 60)
        }

        const stopClientTimeOut = () => {
            if (typeof clientTimeOutSession === 'number') {
                clearTimeout(clientTimeOutSession)
                clientTimeOutSession = false
                clientTimeOutStarted = false

                // console.log('stoped')
            }
        }
        const handleResizeWindow = () => {
            windowResize(appRoot.value)
            emitter.emit(EventBuss.WINDOW_RESIZED)
        }

        const handleToastMessage = (_message: string) => {
            toastMessageCounter.value = 9
            toastMessage.value = _message
            if (typeof toastMessageInterval !== 'number') {
                toastMessageInterval = setInterval(() => {
                    toastMessageCounter.value -= 1
                    if (
                        toastMessageCounter.value <= 0 &&
                        typeof toastMessageInterval === 'number'
                    ) {
                        clearInterval(toastMessageInterval)
                        toastMessageInterval = false
                        toastMessage.value = ''
                    }
                }, 150)
            }
        }

        const initSound = () => {
            if (!isUserInteracted.value) {
                store.commit(AppMutation.SET_USER_INTERACTION)
                audioPlayer.Start()
            }
        }

        const handleOrientationChange = () => {
            closeAllDialogs()

            if (isAMobile()) {
                showMainView.value = false
                setTimeout(() => {
                    handleResizeWindow()
                    showMainView.value = true
                }, 500)
            }
        }

        const requestFullScreen = () => {
            if (screenfull.isEnabled) {
                const docEl = document.getElementById('app') as HTMLElement
                if (!screenfull.isFullscreen) {
                    screenfull
                        .request(docEl, { navigationUI: 'hide' })
                        .then(() => {
                            store.commit(
                                AppMutation.SET_FULL_SCREEN,
                                screenfull.isFullscreen
                            )
                        })
                } else {
                    screenfull.exit().then(() => {
                        store.commit(
                            AppMutation.SET_FULL_SCREEN,
                            screenfull.isFullscreen
                        )
                    })
                }
            }
        }

        const handleDialogEvent = (e: MessageBoxEvent) => {
            if (e.type === MessageBoxActionType.close) {
                if (dialogCollection.value.includes(e.name)) {
                    const _i = dialogCollection.value.indexOf(e.name)
                    dialogCollection.value.splice(_i, 1)
                }

                if (e.name === 'lang') showLanguageDialogBox.value = false
                else if (e.name === 'setting')
                    showSettingDialogBox.value = false
                else if (e.name === 'security')
                    showSecurityDialogBox.value = false
                else if (e.name === 'agree') showAgreeDialogBox.value = false
                else if (e.name === 'chips') showChipsDialogBox.value = false
                else if (e.name === 'demo') showDemoLogin.value = false
                else if (e.name === 'rule') showRuleDialogBox.value = false
                else if (e.name === 'record') showRecordDialogBox.value = false
                else if (e.name === 'message')
                    showMessageDialogBox.value = false
                else if (e.name === 'tipbox')
                    showMessageDialogTipBox.value = false
                else if (e.name === 'tipbox-concent')
                    showMessageDialogTipBoxConcent.value = false
                else if (e.name === 'confirm-booking')
                    showMessageDialogVipBox.value = false
                else if (e.name === 'media-setting')
                    showMessageDialogMediaSettingBox.value = false
                else if (e.name === 'required-change-password')
                    showRequiredChangePassword.value = false
                else if (e.name === 'prompt')
                    showMessageDialogPrompt.value = false
            } else if (e.type === MessageBoxActionType.open) {
                if (!dialogCollection.value.includes(e.name))
                    dialogCollection.value.push(e.name)
                if (e.name === 'lang') showLanguageDialogBox.value = true
                else if (e.name === 'setting') showSettingDialogBox.value = true
                else if (e.name === 'security')
                    showSecurityDialogBox.value = true
                else if (e.name === 'agree') showAgreeDialogBox.value = true
                else if (e.name === 'chips') showChipsDialogBox.value = true
                else if (e.name === 'demo') showDemoLogin.value = true
                else if (e.name === 'rule') showRuleDialogBox.value = true
                else if (e.name === 'record') showRecordDialogBox.value = true
                else if (e.name === 'message') showMessageDialogBox.value = true
                else if (e.name === 'tipbox')
                    showMessageDialogTipBox.value = true
                else if (e.name === 'tipbox-concent')
                    showMessageDialogTipBoxConcent.value = true
                else if (e.name === 'confirm-booking')
                    showMessageDialogVipBox.value = true
                else if (e.name === 'media-setting')
                    showMessageDialogMediaSettingBox.value = true
                else if (e.name === 'required-change-password')
                    showRequiredChangePassword.value = true
                else if (e.name === 'prompt')
                    showMessageDialogPrompt.value = true
            }
        }

        const closeAllDialogs = () => {
            const _arr = [...dialogCollection.value]
            _arr.forEach((_m) => {
                const _ev: MessageBoxEvent = {
                    type: MessageBoxActionType.close,
                    name: _m as DialogBoxName
                }

                handleDialogEvent(_ev)
            })
        }
        //#endregion

        //#region MJSocket Helpers
        const connectToWebsocket = () => {
            if (token.value) {
                console.log('connect')
                let servicePath = ''

                if (serverLine.value > 0) {
                    if (serverLine.value === 1) {
                        servicePath = `${process.env.API1}`
                    } else if (serverLine.value === 2) {
                        servicePath = `${process.env.API2}`
                    } else if (serverLine.value === 3) {
                        servicePath = `${process.env.API3}`
                    }
                } else if (serverLine.value === -1) {
                    servicePath = serverLineStr.value
                }

                const protocol = servicePath.startsWith('https://')
                    ? 'wss://'
                    : 'ws://'

                const wsPath = servicePath.replace(/^https?:\/\//, protocol)
                _ws.close()
                _ws.connect(`${wsPath}/websocketUserServer/${token.value}`)
            }
        }

        const initWebSocket = () => {
            _ws.OnMessage = (message: MessageEvent) => {
                if (typeof message.data === 'string') {
                    const objMessage = JSON.parse(message.data)
                    // const mgType = (objMessage.type as string).toUpperCase()
                    emitter.emit(EventBuss.SOCKET_MESSAGE, objMessage)
                    // console.log(objMessage)

                    const _type = objMessage.type.toUpperCase()
                    // console.log(_type)

                    if (_type !== SOCKET_MESSAGE.SERVER_PONG) {
                        // console.log(objMessage)
                    }

                    if (
                        _type === SOCKET_MESSAGE.USER_OUT ||
                        _type === SOCKET_MESSAGE.USER_OUT_BY
                    ) {
                        // const _evt: MessageBoxEvent = {
                        //     type: MessageBoxActionType.open,
                        //     name: 'message'
                        // }
                        // emitter.emit(EventBuss.DIALOG, _evt)
                        // setTimeout(() => {
                        //     emitter.emit(EventBuss.HINT, objMessage.content)
                        // }, 250)
                        // kick out directly

                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else if (_type === SOCKET_MESSAGE.MEMBER_AMOUNT) {
                        store.commit(
                            AppMutation.SET_COIN,
                            objMessage.data.amount
                        )
                    } else if (_type === SOCKET_MESSAGE.WS_EXPIRED) {
                        window.sessionStorage.clear()
                        const evt: MessageBoxEvent = {
                            type: MessageBoxActionType.open,
                            name: 'message'
                        }
                        emitter.emit(EventBuss.DIALOG, evt)
                        setTimeout(() => {
                            emitter.emit(
                                EventBuss.HINT,
                                t('prompts.socketnetworkerror')
                            )
                        }, 250)
                    } else if (
                        _type === SOCKET_MESSAGE.GUNNER_BET ||
                        _type === SOCKET_MESSAGE.BET
                    ) {
                        const _sDesk = objMessage.gameTable
                        if (_sDesk.expand.memberId === memberid.value) {
                            //
                            // store.commit(AppMutation.SET_CLIENT_TIME_OUT, 15)
                            // reset client timeout to 15 minutes
                            store.commit(AppMutation.SET_CLIENT_TIME_OUT, 10)
                        }
                    }
                }
            }
        }

        const handleCloseSocket = () => {
            _ws.close()
        }

        //#endregion
        const showLoading = computed(
            (): boolean => store.getters['showLoading']
        )

        const canPlaySound = computed(
            (): boolean => store.getters['canPlaySound']
        )
        const isUserInteracted = computed(
            (): boolean => store.getters['isUserInteracted']
        )
        const playerTimeOut = computed(
            (): number => store.getters['playerTimeOut']
        )

        const hasVisibleDialog = computed(
            () => dialogCollection.value.length > 0
        )

        const memberid = computed(() => store.getters['memberid'])

        const token = computed(() => store.getters['token'])
        const serverLine = computed((): number => store.getters['serverLine'])
        const serverLineStr = computed(
            (): string => store.getters['serverLineStr']
        )

        const userInfo = computed((): UserInfo => store.getters['userInfo'])

        watch(
            () => route.name,
            (_n) => {
                // console.log(_n)
                if (typeof _n === 'string' && _n === ROUTES.LOGIN) {
                    // TODO: uncoment this line on publish
                    closeAllDialogs()
                    stopClientTimeOut()
                }

                if (userInfo.value && userInfo.value.firstLogin === 0) {
                    if (
                        typeof _n === 'string' &&
                        _n !== ROUTES.LOGIN &&
                        _n !== ROUTES.SERVER_SELECTION
                    ) {
                        router.push({ name: ROUTES.LOGIN })
                    }
                    const _ev: MessageBoxEvent = {
                        type: MessageBoxActionType.open,
                        name: 'required-change-password'
                    }
                    emitter.emit(EventBuss.DIALOG, _ev)
                }
            }
        )

        return {
            showMainView,
            isMobile,
            appRoot,
            showLoading,
            toastMessage,
            toastMessageCounter,
            hasVisibleDialog,
            showLanguageDialogBox,
            showSettingDialogBox,
            showSecurityDialogBox,
            showAgreeDialogBox,
            showChipsDialogBox,
            showDemoLogin,
            showRuleDialogBox,
            showRecordDialogBox,
            showMessageDialogBox,
            showMessageDialogTipBox,
            showMessageDialogTipBoxConcent,
            showMessageDialogVipBox,
            showMessageDialogMediaSettingBox,
            showRequiredChangePassword,
            showMessageDialogPrompt,
            requestFullScreen,
            initSound
        }
    }
})
