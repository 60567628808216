import '@/utils/string-ext'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'

import VCalendar from 'v-calendar'

import 'v-calendar/dist/style.css'

import '@/styles/index.scss'
import '@/router/route-guard'

import clickOutside from './directives/click-outside'

import mitt from 'mitt'
import EmitterEvents from './types/EmitterEvents'

const emitter = mitt<EmitterEvents>()

import i18n from './utils/i18n'

NodePlayer.debug(false)
NodePlayer.load(() => {
    const app = createApp(App)
    app.directive('click-outside', clickOutside)
    app.provide('emitter', emitter)
    app.use(i18n).use(store, key).use(router).use(VCalendar, {}).mount('#app')
})
