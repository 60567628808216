<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="27"
        viewBox="0 0 24 27"
    >
        <g id="icon_menu_record" transform="translate(1 1)">
            <path
                id="Path_22"
                data-name="Path 22"
                d="M20,25H2a2,2,0,0,1-2-2V9L9,0H20a2,2,0,0,1,2,2V23A2,2,0,0,1,20,25Z"
                fill="none"
                stroke="#ffe0a7"
                stroke-width="2"
            />
            <path
                id="Path_21"
                data-name="Path 21"
                d="M1828.252,319.46v-10l-10,10Z"
                transform="translate(-1818.252 -309.46)"
                fill="#ffe0a7"
            />
            <rect
                id="Rectangle_10"
                data-name="Rectangle 10"
                width="15"
                height="2"
                rx="1"
                transform="translate(4 12)"
                fill="#ffe0a7"
            />
            <rect
                id="Rectangle_11"
                data-name="Rectangle 11"
                width="11"
                height="2"
                rx="1"
                transform="translate(8 16)"
                fill="#ffe0a7"
            />
            <rect
                id="Rectangle_12"
                data-name="Rectangle 12"
                width="8"
                height="2"
                rx="1"
                transform="translate(11 20)"
                fill="#ffe0a7"
            />
        </g>
    </svg>
</template>
