<template>
    <svg
        id="icon_menu_password"
        xmlns="http://www.w3.org/2000/svg"
        width="20.466"
        height="25"
        viewBox="0 0 20.466 25"
    >
        <path
            id="Path_17"
            data-name="Path 17"
            d="M18.419,25.28H3.293c-1.474,0-2.67-.017-2.67-1.514v-9.8a2.69,2.69,0,0,1,2.67-2.71V7.922A7.585,7.585,0,0,1,10.821.28h.068a7.587,7.587,0,0,1,7.531,7.643V11.26a2.69,2.69,0,0,1,2.67,2.71v9.8C21.089,25.263,19.894,25.28,18.419,25.28Zm-8.9-7.4V20.6a1.335,1.335,0,1,0,2.67,0V17.884a2.224,2.224,0,1,0-2.67,0Zm6.229-9.926a4.894,4.894,0,1,0-9.787,0v3.3h9.787Z"
            transform="translate(-0.623 -0.28)"
            fill="#ffe0a7"
            fill-rule="evenodd"
        />
    </svg>
</template>
