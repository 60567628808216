<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37.574"
        height="32"
        viewBox="0 0 37.574 32"
    >
        <g id="icon_login_verifcasion_change" transform="translate(1.328 1)">
            <path
                id="Path_482"
                data-name="Path 482"
                d="M29.425,19.129A15,15,0,0,1,0,15c-.674,1.413-2.493,5.34-2.493,5.34"
                transform="translate(2.493)"
                fill="none"
                stroke="#654b31"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
            />
            <path
                id="Path_483"
                data-name="Path 483"
                d="M-2.493,25.871A15,15,0,0,1,26.932,30c.674-1.413,2.493-5.34,2.493-5.34"
                transform="translate(5.493 -15)"
                fill="none"
                stroke="#654b31"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
            />
        </g>
    </svg>
</template>
