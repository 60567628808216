import { getValueOfKeyValue } from '@/utils/api'
import { TableLimit } from 'vue'

export class DeskMoreInfoData {
    notice?: string
    limit?: TableLimit
    chipsVallue?: Array<number>

    static Parse(strData: string): DeskMoreInfoData {
        const result = new DeskMoreInfoData()
        const keyValues = strData.split('&')
        let numStr
        const limit: TableLimit = { Id: -1, Max: 0, Min: 0 }

        result.chipsVallue = new Array<number>()

        keyValues.forEach((item) => {
            numStr = getValueOfKeyValue(item)

            if (item.startsWith('notice=')) {
                result.notice = numStr
            } else if (item.startsWith('UpXian=')) {
                if (numStr !== undefined) limit.Max = parseInt(numStr)
            } else if (item.startsWith('DownXian=')) {
                if (numStr !== undefined) limit.Min = parseInt(numStr)
            } else if (item.startsWith('DDownXian=')) {
                if (numStr !== undefined) limit.PairMinBet = parseInt(numStr)
            } else if (item.startsWith('HUpXian=')) {
                if (numStr !== undefined) limit.TieMaxBet = parseInt(numStr)
            } else if (item.startsWith('HDownXian=')) {
                if (numStr !== undefined) limit.TieMinBet = parseInt(numStr)
            } else if (item.startsWith('DUpXian=')) {
                if (numStr !== undefined) limit.PairMaxBet = parseInt(numStr)
            } else if (item.startsWith('cm1=')) {
                if (numStr !== undefined)
                    result.chipsVallue?.push(parseInt(numStr))
            } else if (item.startsWith('cm2=')) {
                if (numStr !== undefined)
                    result.chipsVallue?.push(parseInt(numStr))
            } else if (item.startsWith('cm3=')) {
                if (numStr !== undefined)
                    result.chipsVallue?.push(parseInt(numStr))
            } else if (item.startsWith('cm4=')) {
                if (numStr !== undefined)
                    result.chipsVallue?.push(parseInt(numStr))
            } else if (item.startsWith('cm5=')) {
                if (numStr !== undefined)
                    result.chipsVallue?.push(parseInt(numStr))
            }
        })

        result.limit = limit
        return result
    }
}
