import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { Emitter } from 'vue'

export class MJWebsocket {
    /**
     *
     */
    constructor(_emitter: Emitter) {
        this.emitter = _emitter
    }
    ws!: WebSocket
    isConnected = false
    emitter!: Emitter

    heartBeatInterval = 15000
    reconnectInterval = 3000

    heartBeat: number | boolean = false

    private url = ''

    private count = 0
    private reconnectCount = 0
    private hasRecentDataRecieved = false
    private closedByClient = false

    private isOff = false

    // OnOpen!: () => void
    OnMessage!: (event: MessageEvent) => void
    // OnClose!: (event: CloseEvent) => void
    // OnError!: (event: Event) => void

    connect(url: string): void {
        if (this.isOff) return
        if (this.isConnected) return
        this.url = url
        this.ws = new WebSocket(this.url)
        console.log('connecting to ws')

        // create an intance of websocket
        this.ws.onopen = () => {
            this.isConnected = true
            this.reconnectCount = 0
            console.log('connected')

            this.stopHeartBeat()
            this.heartBeat = setInterval(() => {
                if (this.isConnected) {
                    const pingObject = { type: 'client-ping', data: {} }
                    this.ws.send(JSON.stringify(pingObject))
                    // console.log('NO DATA', this.count)

                    if (this.count > 3) {
                        // check if need to reconect
                        if (this.hasRecentDataRecieved) {
                            this.hasRecentDataRecieved = false
                        } else {
                            this.stopHeartBeat()
                            this.close()
                            this.showMessage()
                        }

                        this.count = 0
                    }
                    this.count++
                } else {
                    this.stopHeartBeat()
                }
            }, this.heartBeatInterval)
        }

        // listen to message event
        this.ws.onmessage = (event: MessageEvent) => {
            if (typeof this.OnMessage === 'function') {
                this.count = 0
                this.hasRecentDataRecieved = true
                this.OnMessage(event)
            }
        }

        this.ws.onclose = (event: CloseEvent) => {
            this.isConnected = false
            if (!event.wasClean) {
                setTimeout(() => {
                    if (this.reconnectCount > 5) {
                        this.reconnectCount = 0
                        this.showMessage()
                    } else {
                        console.log('reconnect ws')
                        this.reconnectCount++
                        this.connect(this.url)
                    }
                }, this.reconnectInterval)
            } else {
                console.log('close')
                if (!this.closedByClient) {
                    setTimeout(() => {
                        if (this.reconnectCount > 5) {
                            this.reconnectCount = 0
                            this.showMessage()
                        } else {
                            console.log('reconnect ws')
                            this.reconnectCount++
                            this.connect(this.url)
                        }
                    }, this.reconnectInterval)
                } else {
                    this.closedByClient = false
                }
            }
        }

        this.ws.onerror = (event: Event) => {
            // console.log(event)
            console.log('ws error')
        }
    }

    close(): void {
        if (this.ws) {
            this.closedByClient = true
            this.stopHeartBeat()
            this.ws.close()
        }
    }

    showMessage(): void {
        const evt: MessageBoxEvent = {
            type: MessageBoxActionType.open,
            name: 'message'
        }
        this.emitter.emit(EventBuss.DIALOG, evt)
        setTimeout(() => {
            this.emitter.emit(
                EventBuss.HINT,
                '因网络波动异常，请您重新登录系统，谢谢！'
            )
        }, 250)
    }

    private stopHeartBeat(): void {
        if (typeof this.heartBeat === 'number') {
            clearInterval(this.heartBeat)
            this.heartBeat = false
            console.log('ws heartbeat stoped')
        }
    }
}
