export enum CookiesToken {
    USER_NAME = 'kkw001',
    CUSTOM_CHIP = 'kkw002',
    SOUND = 'kkw003',
    TOKEN = 'kkw004',
    SELECTED_CHIP = 'kkw005',
    POINT = 'kkw006',
    LIMITS = 'kkw007',
    LOBY_NOTICE = 'kkw008',
    DESK_NOTICE = 'kkw009',
    LIMIT = 'kkw010',
    CHIPS = 'kkw011',
    LANG = 'kkw012',
    VIDEO = 'kkw013',
    DESK_OPTIONS = 'kkw014',
    MEMBER = 'kkw015',
    FREE_MEMBER = 'kkw016',
    COMMAND_TYPE = 'kkw017',
    CURRENT_CHIP = 'kkw018',
    USER_INFO = 'kkw019',
    DESK = 'kkw020',
    CLIENT_TIME_COUNT = 'kkw021',
    VISITOR_TABLE = 'kkw022',
    LINE = 'kkw023',
    LINE_STR = 'kkw024',
    MULTI_TABLE_DESK = 'kkw025'
}
