import { RoadMapData } from '@/components/roadmap/datamodel/RoadMapData'
import { GAMETYPE } from '@/components/roadmap/datamodel/types'
import { parseRoadMap } from '@/components/roadmap/utils'
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { Desk } from '@/types/Desk'
import { DeskMoreInfoData } from '@/types/DeskMoreInfoData'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import {
    computed,
    Emitter,
    inject,
    ref,
    TableData,
    TableLimit,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export function useListItem(props: { activeDesk: number; mData: TableData }) {
    const store = useStore()
    const emitter = inject('emitter') as Emitter
    const router = useRouter()
    const currentCount = ref<number>(-1)
    const mapdata = ref<RoadMapData>(parseRoadMap([]))
    const { t } = useI18n()

    const enterToDesk = (_limit: TableLimit) => {
        store.commit(AppMutation.SHOW_LOADING)
        const _param = {
            ...OtherApiParams,
            xianId: _limit.Id,
            desk: props.mData.tableNo,
            act: ACT.GET_NOTICE_AND_LIMIT
        }

        store
            .dispatch(AppActions.FETCH_DATA, _param)
            .then((_data: string) => {
                store.commit(AppMutation.HIDE_LOADING)
                if (_data.includes('err=')) {
                    emitter.emit(EventBuss.SESSION_EXPIRED)
                } else {
                    const { limit, chipsVallue, notice } =
                        DeskMoreInfoData.Parse(_data)
                    const _limit = limit

                    if (_limit) {
                        _limit.Id = _param.xianId
                        store.commit(AppMutation.SET_LIMIT, _limit)
                    }

                    if (chipsVallue)
                        store.commit(AppMutation.SET_CHIPS, chipsVallue)
                    if (notice)
                        store.commit(AppMutation.SET_DESK_NOTICE, notice)
                    router.push({
                        name: ROUTES.GAME,
                        query: {
                            desk: props.mData.tableNo,
                            gameType:
                                props.mData.gameTypeNo.toUpperCase() === 'G002'
                                    ? 1
                                    : 2,
                            xian: _limit?.Id
                        }
                    })
                }
            })
            .catch((_error) => {
                store.commit(AppMutation.HIDE_LOADING)
                emitter.emit(EventBuss.TOAST_MESSAGE, _error)
            })
    }

    const tableName = computed(() => {
        const { tableNo, commandType } = props.mData

        let cmd = ''
        if (commandType === 0) cmd = t('loby.universal')
        else if (commandType === 1) t('loby.phonemode')
        else if (commandType === 2) t('loby.commandmode')

        if (props.mData.gameTypeNo.toUpperCase() === 'G002')
            return `${t('loby.baccarattable')}${tableNo}(${cmd})`
        else return `${t('loby.dragontigertable')}${tableNo}(${cmd})`
    })

    const timerColor = computed((): string => {
        if (currentCount.value > 20) return 'green'
        else if (currentCount.value <= 20 && currentCount.value > 10)
            return 'orange'
        else if (currentCount.value <= 10 && currentCount.value !== -1)
            return 'red'
        return ''
    })

    const shoeRound = computed(() => {
        if (props.mData)
            return `${props.mData.expand.shoeNo}/${props.mData.expand.juNo}}`

        return '0/0'
    })

    const onRoadMapDataParsed = (a: RoadMapData) => {
        mapdata.value = a
    }

    const redCount = computed(() => {
        if (mapdata.value) return mapdata.value.redCount
        return 0
    })

    const blueCount = computed(() => {
        if (mapdata.value) return mapdata.value.blueCount
        return 0
    })

    const greenCount = computed(() => {
        if (mapdata.value) return mapdata.value.tieCount
        return 0
    })

    const redPairCount = computed(() => {
        if (mapdata.value) return mapdata.value.redPairCount
        return 0
    })

    const bluePairCount = computed(() => {
        if (mapdata.value) return mapdata.value.bluePairCount
        return 0
    })

    const limits = computed((): TableLimit[] => store.getters['limits'])
    const activeDesk = computed(() => store.getters['currentDesk'])

    const isAtThisTable = computed((): boolean => {
        if (props.mData) return props.mData.id === activeDesk.value
        return false
    })

    return {
        shoeRound,
        tableName,
        currentCount,
        timerColor,
        redPairCount,
        bluePairCount,
        greenCount,
        blueCount,
        redCount,
        limits,
        isAtThisTable,
        enterToDesk,
        onRoadMapDataParsed,
        t
    }
}
