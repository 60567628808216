<template>
    <svg
        id="icon_login_password"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24.21"
        height="30"
        viewBox="0 0 24.21 30"
    >
        <defs>
            <clipPath id="clip-path">
                <path
                    id="Path_526"
                    data-name="Path 526"
                    d="M158.7,412.95q-10.613-.489-21.237,0A1.513,1.513,0,0,0,136,414.328a89.948,89.948,0,0,0,.837,14.391,2.075,2.075,0,0,0,1.5,1.6,47.461,47.461,0,0,0,19.493,0,2.075,2.075,0,0,0,1.5-1.6,89.934,89.934,0,0,0,.837-14.391A1.512,1.512,0,0,0,158.7,412.95Z"
                    transform="translate(0 -342.017)"
                    fill="#f04d4e"
                />
            </clipPath>
        </defs>
        <path
            id="Path_527"
            data-name="Path 527"
            d="M1533.98,315.312v-10a5.006,5.006,0,0,1,5-5h2v3h-2a2,2,0,0,0-2,2v7h2.5v2Z"
            transform="translate(-1528.98 -300.312)"
            fill="#bcbcbc"
        />
        <path
            id="Path_528"
            data-name="Path 528"
            d="M1540.98,315.312v-10a5.006,5.006,0,0,0-5-5h-2v3h2a2,2,0,0,1,2,2v7h-2.5v2Z"
            transform="translate(-1521.98 -300.312)"
            fill="#e1e1e1"
        />
        <path
            id="Path_523"
            data-name="Path 523"
            d="M158.7,412.95q-10.613-.489-21.237,0A1.513,1.513,0,0,0,136,414.328a89.948,89.948,0,0,0,.837,14.391,2.075,2.075,0,0,0,1.5,1.6,47.461,47.461,0,0,0,19.493,0,2.075,2.075,0,0,0,1.5-1.6,89.934,89.934,0,0,0,.837-14.391A1.512,1.512,0,0,0,158.7,412.95Z"
            transform="translate(-135.98 -401.329)"
            fill="#ffc772"
        />
        <g
            id="Mask_Group_25"
            data-name="Mask Group 25"
            transform="translate(-135.98 -59.312)"
            clip-path="url(#clip-path)"
        >
            <path
                id="Path_525"
                data-name="Path 525"
                d="M148.086,70.689v18.9H135.417v-18.9Z"
                fill="#e0a03d"
            />
        </g>
        <circle
            id="Ellipse_166"
            data-name="Ellipse 166"
            cx="3"
            cy="3"
            r="3"
            transform="translate(9 16)"
            fill="#5d4520"
        />
        <rect
            id="Rectangle_540"
            data-name="Rectangle 540"
            width="2"
            height="7"
            rx="1"
            transform="translate(11 19)"
            fill="#5d4520"
        />
    </svg>
</template>
