
import {
    defineComponent,
    onBeforeUnmount,
    onMounted,
    PagerInfo,
    PropType,
    ref
} from 'vue'
import { iconArrowDown } from '@/components/icons'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['goto-page'],
    props: {
        pagerInfo: {
            type: Object as PropType<PagerInfo>,
            required: true
        },
        pageNumbers: {
            type: Array as PropType<number[]>,
            default: () => []
        }
    },
    components: {
        'icon-arrow': iconArrowDown
    },
    setup(props, { emit }) {
        const { t } = useI18n()

        let typingTimer: number | boolean | any = false
        const stopedTyping = ref(false)

        const directPage = ref<number>(0)
        const gotoPage = (_page: number) => {
            directPage.value = _page
            emit('goto-page', _page)
        }

        onMounted(() => {
            directPage.value = props.pagerInfo.pageNo
        })

        onBeforeUnmount(() => {
            resetTypingTimer()
        })

        const prev = () => {
            if (props.pagerInfo.pageNo > 1) {
                directPage.value = props.pagerInfo.pageNo - 1

                emit('goto-page', directPage.value)
            }
        }

        const next = () => {
            if (props.pagerInfo.pageNo < props.pagerInfo.totalPage) {
                directPage.value = props.pagerInfo.pageNo + 1
                emit('goto-page', directPage.value)
            }
        }

        const resetTypingTimer = () => {
            if (typeof typingTimer === 'number') {
                clearTimeout(typingTimer)
                typingTimer = false
            }
        }

        const onInputChange = () => {
            resetTypingTimer
            typingTimer = setTimeout(() => {
                stopedTyping.value = true
                // got to page

                if (!isNaN(parseFloat(String(directPage.value)))) {
                    if (
                        directPage.value > 0 &&
                        directPage.value <= props.pagerInfo.totalPage
                    ) {
                        emit('goto-page', directPage.value)
                    }
                }
            }, 1000)
        }

        const getPageLabel = (n: number): string => {
            if (n < 1) {
                return '...'
            }

            return `${n}`
        }

        return {
            directPage,
            gotoPage,
            prev,
            next,
            t,
            getPageLabel,
            onInputChange
        }
    }
})
