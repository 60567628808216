import { BaseRoadmapItem } from './BaseRoadmapItem'
import { TypePair } from './types'

export class BigItem extends BaseRoadmapItem {
    tieSize = 0
    hasTie = false
    pair = TypePair.NONE

    addTie() {
        this.tieSize += 1
    }

    hasPair() {
        return this.pair !== TypePair.NONE
    }
}
