
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss, languageOptions } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import audioPlayer from '@/utils/sounds'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onMounted,
    ref,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import nSwitch from '@/components/controls/n-switch.vue'

import { iconArrowDown, iconX } from '@/components/icons'
export default defineComponent({
    components: {
        'icon-arrow-down': iconArrowDown,
        'n-witch': nSwitch,
        'icon-x': iconX
    },
    setup() {
        const store = useStore()
        const emitter = inject('emitter') as Emitter

        const videoIsOn = ref<boolean>(false)
        const soundEffectIsOn = ref<boolean>(false)

        const isMobile = computed((): boolean => store.getters['isMobile'])
        const { t, locale } = useI18n()

        const langArr = ref(languageOptions)
        const showLanguageOptions = ref(false)

        const musicIsOn = ref(true)

        //#region  Vue Helpers

        onBeforeMount(() => {
            videoIsOn.value = isVideoOn.value
            soundEffectIsOn.value = isSoundOn.value
        })
        // onMounted(() => {

        // })
        //#endregion

        const toggleVideo = () => {
            videoIsOn.value = !videoIsOn.value
        }
        const toggleSoundEffect = () => {
            soundEffectIsOn.value = !soundEffectIsOn.value
        }

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'media-setting'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            if (isVideoOn.value !== videoIsOn.value) {
                store.commit(AppMutation.SET_VIDEO, videoIsOn.value)
            }

            if (soundEffectIsOn.value !== isSoundOn.value) {
                const _isSoundOn = soundEffectIsOn.value ? 1 : 0
                store.commit(AppMutation.SET_SOUND, soundEffectIsOn.value)
                if (audioPlayer) audioPlayer.isOn = _isSoundOn
            }

            close()
        }
        const cancel = () => {
            close()
        }

        const selectLanguage = (str: string) => {
            store.commit(AppMutation.SET_LANG, str)
            locale.value = str
            showLanguageOptions.value = false
        }

        const language = computed((): string => {
            const result = langArr.value.find((x) => x.code === lang.value)
            return result?.name || ''
        })

        const isVideoOn = computed((): boolean => store.getters['videoIsOn'])

        const isSoundOn = computed((): boolean => store.getters['canPlaySound'])
        const lang = computed((): string => store.getters['lang'])

        return {
            isMobile,
            videoIsOn,
            soundEffectIsOn,
            showLanguageOptions,
            lang,
            language,
            langArr,
            musicIsOn,
            selectLanguage,
            confirm,
            close,
            cancel,
            toggleVideo,
            toggleSoundEffect,
            t
        }
    }
})
