<template>
    <svg
        id="icon_login_phone"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="23"
        height="30"
        viewBox="0 0 23 30"
    >
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_538"
                    data-name="Rectangle 538"
                    width="17"
                    height="21"
                    rx="2"
                    transform="translate(878 442)"
                    fill="#eaeaea"
                />
            </clipPath>
        </defs>
        <rect
            id="Rectangle_535"
            data-name="Rectangle 535"
            width="21"
            height="30"
            rx="3"
            fill="#e0a03d"
        />
        <rect
            id="Rectangle_539"
            data-name="Rectangle 539"
            width="21"
            height="30"
            rx="3"
            transform="translate(2)"
            fill="#ffc772"
        />
        <rect
            id="Rectangle_536"
            data-name="Rectangle 536"
            width="17"
            height="21"
            rx="2"
            transform="translate(4 4)"
            fill="#eaeaea"
        />
        <circle
            id="Ellipse_165"
            data-name="Ellipse 165"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(11 26)"
            fill="#e0a03d"
        />
        <rect
            id="Rectangle_537"
            data-name="Rectangle 537"
            width="9"
            height="2"
            rx="1"
            transform="translate(8 1)"
            fill="#e0a03d"
        />
        <g
            id="Mask_Group_26"
            data-name="Mask Group 26"
            transform="translate(-874 -438)"
            clip-path="url(#clip-path)"
        >
            <path
                id="Path_536"
                data-name="Path 536"
                d="M876.9,464.355l18.837-24.649-18.837.56Z"
                fill="#d3d3d3"
            />
        </g>
    </svg>
</template>
